$backgroundColor: #ffffff;
$primaryColor: #343a40;
$blue: #007bff;

// Theme colors
$themeColor: #28a745;
$themeInfoColor: #00acd6;
$themeDarkColor: #23272b;
$themeWhiteColor: #ffffff;
$themeDangerColor: #dd4b39;
$themeWarningColor: #e08e0b;
$themeSuccessColor: #008d4c;